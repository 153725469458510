import React from "react"

import SEO from "../../components/seo"
import CenterBreadCrumbs from "../../components/centerBreadCrumbs"

const Philosophy = () => {
  return (
    <>
      <SEO title="Kidd Inn - Philosophy" />

      <CenterBreadCrumbs
        centerName="Kidd Inn"
        centerUrl="/kiddinn/"
        subPageName="Philosophy"
      />

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-12">
            <div
              id="main-page-content"
              className="section-container main-page-content clearfix"
            >
              <div className="section-content">
                <h1 className="page_title">Philosophy</h1>

                <h4>Kidd Inn Early Learning Centre Ltd</h4>

                <div id="content">
                  <p>
                    "Must we always teach our children with books? Let them look
                    at the mountains and the stars up above. Let them look at
                    the beauty of the waters and the trees and flowers on earth.
                    They will then begin to think, and to think is the beginning
                    of a real education" - David Polis.
                  </p>

                  <p>
                    <b>Peaceful, Respectful Communication</b>
                    <br />
                    We believe that positive communication is the key to ensure
                    everyone is well informed and valued in their contribution
                    while feeling respected at all times.
                    <br />
                    We believe that through role-modelling peaceful
                    communication children will learn compassionate dialogue,
                    which will provide life-long skills in situations of
                    conflict and leadership, in addition, impacting on the
                    overall wellness of society.
                    <br />
                    We believe children learn limits and boundaries through love
                    and support.
                    <br />
                    We believe that open honest communication with parents and
                    whanau is the key to successful relationships. <br />
                    We believe that every adult and child within our care
                    deserves to be treated with the respect that is consistent
                    to their cultural heritage.
                  </p>

                  <p>
                    <b>
                      Loving, Homely Environments, which firstly engage the
                      heart and then the mind.....
                    </b>
                    <br />
                    We believe in creating relationships with our children that
                    are built on love, trust and mutual respect. Grounding
                    children to the environment.
                    <br />
                    We believe an emotionally satisfied child is free to fully
                    engage themselves within their surroundings.
                    <br />
                    We believe that little brains needs to feel safe, secure and
                    loved.
                    <br />
                    We believe that our environment should reflect the ideas,
                    ethics, attitudes and culture of the people within it.
                    <br />
                    We believe that the physical environment acts as the third
                    teacher, it therefore needs to entice, captivate, challenge
                    and nourish every individual.
                    <br />
                    We believe "the child should live in an environment of
                    beauty" - Maria Montessori.
                  </p>

                  <p>
                    <b>
                      Learning unfolds naturally...Play is the answer to how
                      anything new comes about...all mammals play!
                    </b>
                    <br />
                    We believe play is the window to all learning and for
                    children to play they need to be emotionally satisfied.
                    <br />
                    We believe meaningful learning experiences only happen when
                    children initiate and engage in opportunities for
                    themselves.
                    <br />
                    We believe children be free to play in their own natural way
                    making their own natural progression.
                    <br />
                    We believe teachers foster learning through play by setting
                    up an environment, which is rich in provocations, allowing
                    children to develop a natural love for enquiry,
                    investigation and invention.
                    <br />
                    We believe all children are naturally creative and teachers
                    foster this through providing raw materials, freedom of
                    time, creative experiences that are holistic and limitless.
                    <br />
                    We believe teachers and children are equal partners in the
                    learning process.
                  </p>
                  <p>
                    <b>
                      Collaboration is Everything…shared vision, shared goals.
                    </b>
                    <br />
                    We believe that together we provide strength, unity, shared
                    understanding and strategies.
                    <br />
                    We believe partnership between management and teacher,
                    child, family and community exist and this strengthens and
                    supports us.
                    <br />
                    We believe in sharing knowledge, praise and recognition on a
                    daily basis.
                    <br />
                    We believe that we are all of equal importance and that we
                    make a difference everyday.
                    <br />
                    We believe that together we inspire one another to be the
                    best we can be, together we achieve greatness.
                  </p>
                  <p>
                    <b>
                      “Teaching children about the natural world should be seen
                      as one of the most important events in their lives” –
                      Thomas Berry
                    </b>
                    <br />
                    We believe nature holds the key essential elements to
                    holistic growth and development.
                    <br />
                    We believe by installing a love and sense of wonder about
                    nature in every child we are creating guardians of our
                    earth.
                    <br />
                    We believe that the outdoor environment is pivotal to the
                    development of a healthy body, soul and spirit.
                    <br />
                    We believe natural elements provide for open-ended play that
                    emphasise unstructured creative exploration with diverse
                    materials – the high levels of complexity and variety nature
                    offers invites longer and more complex play.
                    <br />
                    We believe that working alongside children to develop more
                    sustainable practices is to help ensure our children’s
                    future.
                    <br />
                    We believe that playing and engaging in nature evokes the
                    children’s imagination and creativity while being
                    meaningful, abundant and able to create multiple
                    opportunities.
                    <br />
                    We believe that through opportunities to explore our
                    beautiful country we are able to pass on legends and myths
                    of Maori, and honouring Maori as Tangata Whenua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Philosophy
